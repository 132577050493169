<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item :to="`/${home}`">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item :to="{ name: 'user' }">User</b-breadcrumb-item>
      <b-breadcrumb-item active>Edit User</b-breadcrumb-item> </b-breadcrumb
    ><br />

    <b-card title="Edit User">
      <div class="backButton" style="margin-bottom : 15px">
        <b-button variant="primary" :to="{ name: 'user' }">Back</b-button>
      </div>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="6">
              <span>Firstname</span>
              <b-form-group label-for="form-firstname">
                <b-form-input
                  id="form-firstname"
                  v-model="form.props.firstName"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group label-for="form-role">
                <span>Role</span>
                <v-select
                  placeholder="----- Select Role -----"
                  label="label"
                  v-model="form.roleRef"
                  :options="roles"
                  :clearable="false"
                />
              </b-form-group>

              <b-form-group label-for="form-emailname">
                <span>Email</span>
                <b-form-input id="form-emailname" v-model="form.email">
                </b-form-input>
              </b-form-group>

              <b-form-group
                v-if="newFieldStatus"
                label-for="form-identificationNumber"
              >
                <span>Identification Number </span>
                <b-form-input
                  id="form-identificationNumber"
                  v-model="form.props.identificationNumber"
                  type="number"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group label-for="form-role">
                <span>Logged In</span>
                <v-select
                  label="label"
                  v-model="form.props.loggedIn"
                  :options=[true,false]
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Last Name" label-for="form-lastname">
                <b-form-input id="form-lastname" v-model="form.props.lastName">
                </b-form-input>
              </b-form-group>

              <b-form-group
                v-if="newFieldStatus"
                label="Phone"
                label-for="form-phone"
              >
                <b-form-input
                  id="form-phone"
                  v-model="form.props.phone"
                  type="number"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group label-for="form-username">
                <span>Username</span>
                <b-row>
                  <b-col>
                    <b-form-input
                      name="Username"
                      rules="required"
                      placeholder="username"
                      v-model="form.username"
                    >
                    </b-form-input>
                  </b-col>

                  <b-button
                    class="mr-1 w-5"
                    variant="success"
                    @click="availUsername()"
                  >
                    check username
                  </b-button>
                </b-row>
              </b-form-group>

              <b-form-group
                v-if="newFieldStatus"
                label="Address"
                label-for="form-address"
              >
                <b-form-textarea
                  id="form-address"
                  v-model="form.props.address"
                  placeholder="Enter address..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="4"></b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4">
              <b-button
                class="float-right"
                size="md"
                variant="success"
                @click="save"
              >
                Update User
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { dateFormat, userAccess, viewAccess } from "@/utils/utils.js";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { required, email } from "@validations";
import axios from "@/axios";
import { mapActions } from "vuex";

export default {
  components: {
    vSelect,
    "validation-observer": ValidationObserver,
    "validation-provider": ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  props: ["id"],
  data() {
    return {
      strength: "",
      character: "",
      promptAddEdit: false,
      addEdit: "",
      title: "",
      pageOptions: [5, 10, 20, 100],
      sortBy: "",
      sortDesc: false,
      newFieldStatus: true,
      form: {
        email: "",
        username: "",
        password: "",
        role: "",
        roleRef: "",
        props: {
          firstName: "",
          lastName: "",
          address: "",
          props: "",
          phone: "",
          identificationNumber: "",
          app: "",
          loggedIn:false
        },
      },
    };
  },
  methods: {
    ...mapActions({
      getUser: "auth/fetchUser",
    }),
    save() {
      this.form.props.app = this.$session.get("phibase-app");
      if (this.form.roleRef.name) {
        if (this.form.roleRef.name == this.user.roleRef.name) {
          this.form.roleRef = this.roles.find((el) => {
            return el.name == this.user.roleRef.name;
          });
        } else {
          this.form.roleRef = this.form.roleRef;
        }
      } else {
        this.form.roleRef = this.roles.find((el) => {
          return el.name == this.user.roleRef.name;
        });
      }
      this.form.role = this.form.roleRef.name;

      this.$store
        .dispatch("user/updateUser", {
          id: this.user.id,
          data: {
            ...this.form,
          },
        })
        .then(() => {
          this.$bvToast.toast("Successfully Update User", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log({ err });
          for (let i = 0; i < err.response.data.errors.length; i++) {
            this.$bvToast.toast(err.response.data.errors[i].message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });
    },

    clearFields() {
      this.form = {
        email: "",
        password: "",
        role: "",
        roleRef: "",
        props: {
          firstName: "",
          lastName: "",
          address: "",
          props: "",
          phone: "",
          identificationNumber: "",
          app: "",
          loggedIn:false
        },
      };
    },
    getid() {
      console.log(this.user.id);
    },
    availUsername() {
      const usernames = this.users.map((user) => user.username);
      if(this.form.username == "") {
        this.$bvToast.toast("please fill Username", {
          title: "Username is empty",
          autoHideDelay: 3000,
          variant: "danger",
          solid: true,
        });
      }

      if(this.form.username != this.user.username){
        if (
        usernames.includes(this.form.username) ||
        this.form.username.includes(" ") ||
        this.form.username.length < 5
      ) {
        this.$bvToast.toast("This Username can not be used", {
          title: "This Username is unavailable",
          autoHideDelay: 3000,
          variant: "danger",
          solid: true,
        });
      } else if (!usernames.includes(this.form.username)) {
        this.$bvToast.toast("you can use this username", {
          title: "This Username available",
          autoHideDelay: 3000,
          variant: "success",
          solid: true,
        });
      }
      }
    },
  },

  computed: {
    roles() {
      var getRoles = this.$store.getters["role/getRole"]
        ? this.$store.getters["role/getRole"]
            .filter((doc) => {
              return doc.name !== "Super Admin" && doc.level !== "Super Admin";
            })
            .map((el) => {
              return {
                ...el,
                label: `${el.name} - ${el.level}`,
              };
            })
        : [];
      return getRoles;
    },
    home() {
      return viewAccess()[0];
    },
    users() {
      var getUsers = this.$store.getters["user/getUser"]
        ? this.$store.getters["user/getUser"]
            .filter((doc) => {
              return doc.role !== "Super Admin";
            })
            .map((el) => {
              return {
                ...el,
                level: el.roleRef.level,
                createdAtFormatted: dateFormat(el.createdAt),
                updatedAtFormatted: dateFormat(el.updatedAt),
              };
            })
        : [];
      return getUsers;
    },
    roles() {
      var getRoles = this.$store.getters["role/getRole"]
        ? this.$store.getters["role/getRole"]
            .filter((doc) => {
              return doc.name !== "Super Admin" && doc.level !== "Super Admin";
            })
            .map((el) => {
              return {
                ...el,
                label: `${el.name} - ${el.level}`,
              };
            })
        : [];
      return getRoles;
    },
  },
  created() {
    document.title = "User | Phibase - Common";
  },
  async mounted() {
    await this.$store
      .dispatch("user/fetchUser")
      .catch((err) => console.log(err));
    await this.$store
      .dispatch("role/fetchRole")
      .catch((err) => console.log(err));

    this.user = this.users.find((i) => i.id === this.$route.params.id);
    if (this.user.props.identificationNumber !== undefined) {
      this.newFieldStatus = true;
    } else {
      this.newFieldStatus = false;
    }

    this.form = {
      ...this.user,
      roleRef: `${this.user.roleRef.name} - ${this.user.roleRef.level}`,
    };
  },
};
</script>

<style>
@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
</style>
